<script>
export default {
  name: "PageNotFound"
}
</script>

<template>
  <div class="container page-not-found-container">
    <div class="wrapper page-not-found-wrapper">

      <div class="row redirect-wrapper">
        <div class="box redirect-box">
          <h1 class="heading mt-sm">Don't know where you are?</h1>
          <p class="mt-sm">We really have no idea either...</p>
          <div class="button-block mt-sm">
            <a
              href="/"
              class="button"
              style="text-decoration: none"
            >
              Go back home
            </a>
          </div>
        </div>

        <div class="page-not-found-icon"></div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '../assets/styles/component_styles/page-not-found';

.page-not-found-icon {
  background: url("../assets/img/svg/404.svg") no-repeat center;
  background-size: contain;
  height: 300px;
  width: 100%;
  filter: invert(65%) sepia(12%) saturate(6553%) hue-rotate(189deg) brightness(111%) contrast(107%);
}

@media screen and (max-width: 830px) {
  .page-not-found-container .page-not-found-wrapper .redirect-wrapper .redirect-box {
    align-items: center;
    text-align: center;
  }
  .page-not-found-icon {
    display: none;
  }
}
</style>